import React from "react";
import { Slide } from "react-awesome-reveal";
import Button from "@mui/material/Button";

const NavBar = ({ signedin, isLocalRun }) => {
  if (signedin) {
    return null;
  }

  return (
    <header className="fixed top-0 left-0 w-full z-50 overflow-x-hidden">
      <Slide direction="down">
        <nav className="bg-transparent backdrop-blur-sm border-b shadow-2xl lg:px-24
        md:px-12 px-8 flex justify-between items-center h-14">
          <div className="flex items-center">
            <a
                href="/"
                className="font-bold text-gray-400 text-3xl sm:text-2xl"
            >
              Ace<span className="text-color2">Hub</span>.Ai
            </a>
            {/*<a*/}
            {/*    href="/articles"*/}
            {/*    className="text-gray-700 text-md ml-20 border-2 rounded-md pl-1 pr-1"*/}
            {/*>*/}
            {/*  Articles*/}
            {/*</a>*/}
            {/*<a*/}
            {/*    href="/"*/}
            {/*    className="text-gray-700 text-md ml-5 border-2 rounded-md pl-1 pr-1"*/}
            {/*>*/}
            {/*  Quizzes*/}
            {/*</a>*/}
            {/*<a*/}
            {/*    href="/"*/}
            {/*    className="text-gray-700 text-md ml-5 border-2 rounded-md pl-1 pr-1"*/}
            {/*>*/}
            {/*  Cheat Sheets*/}
            {/*</a>*/}
            {/*<a*/}
            {/*    href="/"*/}
            {/*    className="text-gray-700 text-md ml-5 border-2 rounded-md pl-1 pr-1"*/}
            {/*>*/}
            {/*  Mock Interview*/}
            {/*</a>*/}
            {/*<a*/}
            {/*    href="/"*/}
            {/*    className="text-gray-700 text-md ml-5 border-2 rounded-md pl-1 pr-1"*/}
            {/*>*/}
            {/*  Subscribe*/}
            {/*</a>*/}
            {/*<a*/}
            {/*    href="/"*/}
            {/*    className="text-gray-700 text-md ml-5 border-2 rounded-md pl-1 pr-1"*/}
            {/*>*/}
            {/*  Open Projects*/}
            {/*</a>*/}
            {/*<a*/}
            {/*    href="/"*/}
            {/*    className="text-gray-700 text-md ml-5 border-2 rounded-md pl-1 pr-1"*/}
            {/*>*/}
            {/*  Set Appointment*/}
            {/*</a>*/}
            {/*<a*/}
            {/*    href="/"*/}
            {/*    className="text-gray-700 text-md ml-5 border-2 rounded-md pl-1 pr-1"*/}
            {/*>*/}
            {/*  About Us*/}
            {/*</a>*/}
          </div>
        </nav>
      </Slide>
    </header>
  );
};

export default NavBar;
