import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
import Modal from "./Modal";
import "tailwindcss/tailwind.css";
import blogsState from "../store/blogState";
import Author from "../Author";

const RightSection = ({ blogName, isMarkdown }) => {
  const {  setBlogTags,blogTags } = blogsState((state) => ({
    blogTags: state.blogTags,
    setBlogTags: state.setBlogTags,
  }));
  const [authors, setAuthors] = useState(blogTags);
  const [newAuthor, setNewAuthor] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    index: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddAuthor = () => {
    if (newAuthor.trim()) {
      if (editIndex >= 0) {
        const updatedAuthors = [...authors];
        updatedAuthors[editIndex] = newAuthor;
        setAuthors(updatedAuthors);
        setEditIndex(-1);
      } else {
        setAuthors([...authors, newAuthor]);
      }
      setNewAuthor("");
      setIsModalOpen(false);
      
    }
  };

  const handleEditAuthor = (index) => {
    setNewAuthor(authors[index]);
    setEditIndex(index);
    setIsModalOpen(true);
    setContextMenu({ visible: false, x: 0, y: 0, index: null });
  };

  const handleDeleteAuthor = (index) => {
    const updatedAuthors = authors.filter((_, i) => i !== index);
    setAuthors(updatedAuthors);
    setContextMenu({ visible: false, x: 0, y: 0, index: null });
  };

  const handleRightClick = (event, index) => {
    event.preventDefault();
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      index,
    });
  };

  const handleClickOutside = () => {
    if (contextMenu.visible) {
      setContextMenu({ visible: false, x: 0, y: 0, index: null });
    }
  };
  const { authorModalOpen,AuthorModalOpen } = blogsState((state) => ({
    authorModalOpen: state.authorModalOpen,AuthorModalOpen: state.AuthorModalOpen,
  }));
  useEffect(() => {
    AuthorModalOpen(isModalOpen);
    

  }, [isModalOpen]);
  useEffect(() => {
    setIsModalOpen(authorModalOpen);

  }, [authorModalOpen]);
  return (
    <div
      className="w-full scrollbar overflow-auto"
      onClick={handleClickOutside}
    >
      <div className="w-full flex justify-between">
        {/* <div className="flex-end">
          <button
            onClick={() => setIsModalOpen(true)}
            className="btn text-white px-2 py-1 rounded"
          >
            Add Author
          </button>
        </div> */}
      </div>
      <div className="bg-white overflow-auto mb-1 rounded-lg ">
        <ul className="flex flex-wrap h-1/8 scrollbar">
          {authors.map((author, index) => (
            <li
              className="flex items-center mb-2 cursor-pointer"
              key={index}
              onContextMenu={(e) => handleRightClick(e, index)}
            >
              {/* <span className="mr-4">{author}</span> */}
              <Author name={author}/>
            </li>
          ))}
        </ul>
        {contextMenu.visible && (
          <div
            className="absolute bg-white border border-gray-800 rounded-lg shadow-lg p-2"
            style={{ top: contextMenu.y - 30, left: contextMenu.x - 100 }}
          >
            <button
              onClick={() => handleEditAuthor(contextMenu.index)}
              className="bg-gray-600 text-white px-2 py-1 rounded mb-2 w-full"
            >
              Edit
            </button>
            <button
              onClick={() => handleDeleteAuthor(contextMenu.index)}
              className="bg-gray-600 text-white px-2 py-1 rounded w-full"
            >
              Delete
            </button>
          </div>
        )}
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="flex flex-col">
          <input
            type="text"
            value={newAuthor}
            onChange={(e) => setNewAuthor(e.target.value)}
            placeholder="Add or edit an author"
            className="py-1 px-2 border border-gray-800 rounded-lg mr-2 mb-2"
          />
          <button
            onClick={handleAddAuthor}
            className="btn text-white px-2 py-1 rounded"
          >
            {editIndex >= 0 ? "Update" : "Add"}
          </button>
        </div>
      </Modal>
      <div className="prose lg:prose-md">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeHighlight]}
        >
          {isMarkdown}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default RightSection;
