import React, { useEffect, useState } from "react";
import remarkGfm from "remark-gfm";
import "./BlogComp.css";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import styles from "./BlogComp.module.css";
import rehypeSlug from "rehype-slug";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Modal from "./Modal";
import blogsStore from "./store/blogsStore.js";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import { extractHeadings } from "./Utils/blogUtils.js";
import blogsState from "./store/blogState.js";
import CustomImage from "./CustomComponents/CustomImage";

const BlogComp = ({ useremail }) => {
  const [headings, setHeadings] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHeading, setSelectedHeading] = useState('');
  const closeModal = () => setIsModalOpen(false);

  const { articles, article, fetchPublishedBlogsForUser, deleteBlogForUser } =
    blogsStore((state) => ({
      articles: state.articles,
      article: state.article,
      fetchPublishedBlogsForUser: state.fetchPublishedBlogsForUser,
      deleteBlogForUser: state.deleteBlogForUser,
    }));
  const { setBlogName, setBlogContent, setBlogTags, setBlogId, blogName } = blogsState(
    (state) => ({
      setBlogName: state.setBlogName,
      setBlogContent: state.setBlogContent,
      setBlogTags: state.setBlogTags,
      setBlogId: state.setBlogId,
      blogName: state.blogName,
    })
  );

  useEffect(() => {
    if (selectedBlog) {
      setBlogName(selectedBlog.name);
      setBlogContent(selectedBlog.content);
      setBlogTags(selectedBlog.tags || []);
      setBlogId(selectedBlog.id);

      const extractedHeadings = extractHeadings(selectedBlog.content);
      setHeadings(extractedHeadings);
      setSelectedHeading(extractedHeadings[0]?.id || '');
    }
  }, [selectedBlog, setBlogName, setBlogContent, setBlogTags, setBlogId]);

  useEffect(() => {
    const category = "aiml";
    fetchPublishedBlogsForUser(useremail, category);
  }, [fetchPublishedBlogsForUser, useremail]);

  useEffect(() => {
    if (article) {
      setSelectedBlog(article);
    }
  }, [article]);

  const handleHeadingClick = (headingId) => {
    setSelectedHeading(headingId);
    const element = document.getElementById(headingId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  useEffect(() => {
    if (selectedHeading) {
      const element = document.getElementById(selectedHeading);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  }, [selectedHeading]);

  function handleBlogDelete() {
    const confirmDelete = window.confirm("Delete Blog: " + selectedBlog.name);
    if (confirmDelete) {
      console.log("handleBlogDelete is called", selectedBlog.id);
      deleteBlogForUser(useremail, selectedBlog.id);
    }
  }

  function NewBlogCreate() {
    setBlogId("");
    setIsModalOpen(true);
  }
  const components = {
    img: CustomImage
  };

  return (
    <div className="w-[98vw] h-[85vh] container justify-self-center static absolute bottom-0" style={{top: "70px"}}>
      <div className="column toc ml-5 mr-5" style={{ maxWidth: "15vw" }}>
        <div className="flex justify-between items-center mb-4">
          <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>Articles</h3>
          {/*<AddBoxOutlinedIcon*/}
          {/*  onClick={() => NewBlogCreate()}*/}
          {/*  style={{ cursor: "pointer" }}*/}
          {/*/>*/}
        </div>

        <ul style={{ overflowY: "auto" }}>
          {articles.map((blog, index) => (
            <li
              key={index}
              className="bg-gray-200 my-2 p-2 rounded text-md font-semibold hover:bg-gray-300"
              style={{ marginLeft: "0rem", cursor: "pointer" }}
              onClick={() => setSelectedBlog(blog)}
            >
              {blog.name}
            </li>
          ))}
        </ul>
        <Modal
          verifiedemail={useremail}
          isOpen={isModalOpen}
          onClose={closeModal}
        ></Modal>
      </div>
      <div
        className="column content toc flex flex-col shadow-md border-t-2 border-gray-600"
        style={{
          backgroundColor: "#d3d3d30f",
          borderRadius: "10px",
          width: "70vw",
        }}
      >
        <div className="flex mr-2 gap-x-4 justify-end mb-2">
          <div className="font-bold">
            {blogName}
          </div>
          {/*<EditNoteOutlinedIcon onClick={() => setIsModalOpen(true)} />*/}
          {/*<PublishedWithChangesOutlinedIcon />*/}
          {/*<UnpublishedOutlinedIcon />*/}
          {/*<DeleteOutlineOutlinedIcon*/}
          {/*  className="hover:bg-red-300"*/}
          {/*  onClick={handleBlogDelete}*/}
          {/*/>*/}
          <IosShareOutlinedIcon />
        </div>

        <div
          className="prose lg:prose-xl pl-2 h-[85vh] overflow-y-auto scrollbar"
          style={{
            border: "1px solid lightgrey",
            borderRadius: "10px",
            maxWidth: "100vw",
          }}
        >
          {selectedBlog ? (
            <ReactMarkdown
              className={styles.markdown}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeHighlight, rehypeSlug]}
              components={components}
            >
              {selectedBlog.content}
            </ReactMarkdown>
          ) : (
            <p>Acehub.ai</p>
          )}
        </div>
      </div>

      <div className="column toc ml-5" style={{ maxWidth: "15vw" }}>
        <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>Article TOC</h3>
        <div className="column toc mt-4 h-[70vh] p-0 overflow-y-auto scrollbar mr-0">
          <ul>
            {headings.map((heading, index) => (
              <li
                key={index}
                style={{
                  marginLeft: (heading.depth - 1) * 10,
                  fontWeight: 900 - (heading.depth - 1) * 150,
                  color: selectedHeading === heading.id ? 'green' : 'black',
                  textDecoration: selectedHeading === heading.id ? 'underline' : 'none',
                  cursor: 'pointer'
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleHeadingClick(heading.id);
                }}
              >
                {heading.text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BlogComp;
