// Blog dummy data

//   const blogs = [
//     { id: "blog1", text: "Introduction" },
//     { id: "frontend", text: "Frontend" },
//     { id: "backend", text: "Understanding" },
//     { id: "ai", text: "AI Basics" },
//     { id: "blog1", text: "Introduction" },
//     { id: "frontend", text: "Frontend" },
//     { id: "backend", text: "Understanding" },
//     { id: "ai", text: "AI Basics" },
//     { id: "blog1", text: "Introduction" },
//     { id: "frontend", text: "Frontend" },
//     { id: "backend", text: "Understanding" },
//   ];

export const markdownData = `
### Markdown Guide

Markdown is a lightweight markup language with plain text formatting syntax. Here are some of the elements you can use in Markdown. Markdown is a lightweight markup language with plain text formatting syntax. Here are some of the elements you can use in Markdown.

## Text Elements

A paragraph with *emphasis* and **strong importance**.

You can also use ~~strikethrough~~ to mark text that is no longer relevant.

> A block quote with ~strikethrough~ and a URL: [ReactJS](https://reactjs.org).

## Lists

### Unordered List
* Item 1
* Item 2
  * Subitem 2.1
  * Subitem 2.2
* Item 3

### Ordered List
1. First item
2. Second item
   1. Subitem 2.1
   2. Subitem 2.2
3. Third item

### Task List
* [ ] Task 1
* [x] Task 2
* [ ] Task 3

## Tables

You can create tables using pipes and dashes:

| Header 1 | Header 2 | Header 3 |
| -------- | -------- | -------- |
| Row 1    | Data     | More Data|
| Row 2    | Data     | More Data|
| Row 3    | Data     | More Data|

## Code Blocks

### Inline Code

You can include inline code like this: \`const a = 10;\`

### Code Block with Syntax Highlighting

#### Python

\`\`\`python
def hello_world():
    print("Hello, world!")

hello_world()
\`\`\`

#### JavaScript

\`\`\`javascript
function helloWorld() {
    console.log("Hello, world!");
}

helloWorld();
\`\`\`

#### Java

\`\`\`java
public class HelloWorld {
    public static void main(String[] args) {
        System.out.println("Hello, world!");
    }
}
\`\`\`

## Images

You can also embed images:

![React Logo](https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg)

## Links

You can create links like this: [Google](https://www.google.com).

## Horizontal Rule

---

## Nested Blockquote

> This is a nested blockquote.
>
> > Nested quote.
>
> Back to the first level.

## Definition List

Term 1
: Definition 1

Term 2
: Definition 2
  : Subdefinition

## Footnotes

You can create footnotes like this[^1].

[^1]: This is a footnote.

## Emojis

You can use emojis too: :smile: :thumbsup: :tada:

## Task List

- [ ] Write the press release
- [ ] Update the website
- [ ] Contact the media
- [x] Release the product

## Nested Lists

1. First ordered list item
2. Another item
   * Unordered sub-list.
3. Actual numbers don't matter, just that it's a number
   1. Ordered sub-list
   2. 2nd
4. And another item.

   You can have properly indented paragraphs within list items. Notice the blank line above, and the leading spaces (at least one, but we'll use three here to also align the raw Markdown).

   To have a line break without a paragraph, you will need to use two trailing spaces.
   Note that this line is separate, but within the same paragraph.
   (This is contrary to the typical GFM line break behaviour, where trailing spaces are not required.)

## HTML Elements

You can also include HTML elements in Markdown:

<details>
  <summary>Click to expand!</summary>
  <p>Here is some hidden text!</p>
</details>

<kbd>Ctrl</kbd> + <kbd>C</kbd> to copy text.

## Abbreviations

Markdown also supports abbreviations. Here is an example:

The HTML specification is maintained by the W3C.

*[HTML]: Hyper Text Markup Language
*[W3C]: World Wide Web Consortium
`;