import React, { useState } from "react";
import "tailwindcss/tailwind.css";
import RightSection from "./RightSection";
import { markdownData } from "../store/BlogData/data";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import blogsState from "../store/blogState";
import blogsStore from "../store/blogsStore";

function Home({ verifiedemail }) {
  const { createNewBlogForUser,updateBlogForUser } = blogsStore((state) => ({
    createNewBlogForUser: state.createNewBlogForUser,
    updateBlogForUser: state.updateBlogForUser,
  }));
  const {
    AuthorModalOpen,
    newblogName,
    blogContent,
    
    blogId,
  } = blogsState((state) => ({
    newblogName: state.blogName,
    blogContent: state.blogContent,
    AuthorModalOpen: state.AuthorModalOpen,
    
    blogId: state.blogId,
  }));

  const data = `${markdownData}`;
  const [isMarkdown, setIsMarkdown] = useState(blogContent);
  const [notes, setNotes] = useState([{ name: "check", content: "note 1" }]);
  const [blogName, setBlogName] = useState(newblogName);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);

  const { deleteBlogForUser } = blogsStore((state) => ({
    deleteBlogForUser: state.deleteBlogForUser,
  }));

  const insertAtCursor = (text) => {
    const textarea = document.getElementById("editor");
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const beforeText = isMarkdown.substring(0, start);
    const afterText = isMarkdown.substring(end);
    const newText = beforeText + "\n" + text + "\n" + afterText;
    setIsMarkdown(newText);
    textarea.focus();
    textarea.selectionStart = textarea.selectionEnd = start + text.length + 2;
  };

  const handleVideo = () =>
    insertAtCursor("[![Video Label](thumbnail_url)](video_url)");

  const handleBold = () => insertAtCursor("**bold text**");
  const handleItalic = () => insertAtCursor("*italic text*");
  const handleStrikethrough = () => insertAtCursor("~~strikethrough~~");
  const handleUnderline = () => insertAtCursor("<u>underline</u>");
  const handleCode = () => insertAtCursor("`code snippet`");
  const handleLink = () => insertAtCursor("[link text](url)");
  const handleImage = () => insertAtCursor("![alt text](image_url)");
  const handleQuote = () => insertAtCursor("> blockquote");
  const handleHeader1 = () => insertAtCursor("# Header 1");
  const handleHeader2 = () => insertAtCursor("## Header 2");
  const handleHeader3 = () => insertAtCursor("### Header 3");
  const handleUnorderedList = () =>
    insertAtCursor(
      "* Item 1" +
        "\n" +
        "* Item 2" +
        "\n" +
        "  * Subitem 2.1" +
        "\n" +
        "  * Subitem 2.1"
    );
  const handleOrderedList = () =>
    insertAtCursor(
      "1. Item 1" +
        "\n" +
        "2. Item 2" +
        "\n" +
        "   1. Subitem 2.1" +
        "\n" +
        "   2. Subitem 2.1"
    );

  const handleSave = () => {
    const name = blogName;
    const title = blogName;
    const content = isMarkdown;
    const authorEmail = verifiedemail;
    const tags = ["tag1"];
    const category = "unpublish";
    if (blogId === "") {
      console.log("I am calling createNewBlogForUser");

      createNewBlogForUser(
        verifiedemail,
        name,
        title,
        authorEmail,
        content,
        tags,
        category
      );
    } else {
      updateBlogForUser(
        verifiedemail,
        blogId,
        name,
        title,
        authorEmail,
        content,
        tags,
        category
      );
    }

    const existingNoteIndex = notes.findIndex((note) => note.name === blogName);
    if (existingNoteIndex >= 0) {
      const updatedNotes = [...notes];
      updatedNotes[existingNoteIndex] = {
        ...updatedNotes[existingNoteIndex],
        content: isMarkdown,
      };
      setNotes(updatedNotes);
    } else {
      setNotes([...notes, { name: blogName, content: isMarkdown }]);
    }
    setBlogName("Blog Title");
    setIsMarkdown("");
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className="h-full w-full flex flex-col items-center justify-around p-4">
      <div className="flex w-full h-full justify-around">
        <div
          className={`${isExpanded ? "w-3/12" : "w-5/12"} flex flex-col mr-2`}
        >
          <div className="flex flex-wrap mb-4">
            <div className="w-full">
              <button className="editor-button  " onClick={handleBold}>
                <b>B</b>
              </button>
              <button className="editor-button italic" onClick={handleItalic}>
                I
              </button>
              <button
                className="editor-button line-through"
                onClick={handleStrikethrough}
              >
                S
              </button>
              <button
                className="editor-button underline"
                onClick={handleUnderline}
              >
                U
              </button>
              <button className="editor-button" onClick={handleCode}>
                {"<>"}
              </button>
              <button className="editor-button" onClick={handleQuote}>
                ¶
              </button>
              <button className="editor-button" onClick={handleHeader1}>
                H1
              </button>
              <button className="editor-button" onClick={handleHeader2}>
                H2
              </button>
              <button className="editor-button" onClick={handleHeader3}>
                H3
              </button>
              <button className="editor-button" onClick={handleUnorderedList}>
                {"•"}
              </button>
              <button className="editor-button" onClick={handleOrderedList}>
                {"1."}
              </button>
              <button className="editor-button" onClick={handleLink}>
                {"L"}
              </button>
              <button className="editor-button" onClick={handleImage}>
                {"I"}
              </button>
              <button className="editor-button" onClick={handleVideo}>
                V
              </button>
            </div>
          </div>
          <h2 className="text-xl font-bold mb-2">Title :{blogName}</h2>
          <textarea
            id="editor"
            className="w-full h-screen p-4 border-2 border-gray-900 rounded-lg shadow-lg scrollbar"
            value={isMarkdown}
            onChange={(e) => setIsMarkdown(e.target.value)}
          />
        </div>
        <div
          className={`${
            isExpanded ? "w-8/12" : "w-6/12"
          } h-full p-4 flex flex-col rounded-lg shadow-lg bg-white overflow-auto`}
        >
          <div className="w-full flex flex-col justify-between">
            <div className="w-full h-12 flex items-center justify-around">
              <input
                type="text"
                className="w-3/6 p-2 mb-4 border border-gray-800 rounded-lg shadow-lg"
                value={blogName}
                onChange={(e) => setBlogName(e.target.value)}
              />
              <button className="btn px-0 mb-4 w-1/12" onClick={handleSave}>
                Create
              </button>
              <div className="flex items-center mb-4 mr-2">
                <button className="btn " onClick={toggleExpand}>
                  {isExpanded ? "-" : "+"}
                </button>
                <PublishedWithChangesOutlinedIcon className="hover:bg-green-300 mr-2" />
                <UnpublishedOutlinedIcon className="hover:bg-purple-300 mr-2" />
                <DeleteOutlineOutlinedIcon className="hover:bg-red-300 mr-2" />
                <IosShareOutlinedIcon className="hover:bg-blue-300 mr-2" />

                <PersonAddAlt1Icon onClick={() => AuthorModalOpen(true)} />
              </div>
            </div>
          </div>

          <div className="w-full h-full flex flex-col overflow-auto">
            <RightSection blogName={blogName} isMarkdown={isMarkdown} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
