
import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Home from './Home/Home';
const Modal = ({ verifiedemail, isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
       
      <div style={{width:"86vw",height:"90vh"}} className="bg-gray-100 p-5 rounded-lg shadow-lg relative">
       
        <HighlightOffIcon onClick={onClose} className="absolute top-0 right-0 m-2 pointer"></HighlightOffIcon>
        
        <Home verifiedemail={verifiedemail}/>
      </div>
    </div>
  );
};

export default Modal;
