import React from 'react';

const CustomImage = ({ alt, src, title }) => {
    // Default width and height
    let width = '500px';
    let height = '500px';

    // Parse the title for width and height (e.g., "width=300;height=200")
    if (title) {
        const dimensions = title.split(';');
        dimensions.forEach(dimension => {
            const [key, value] = dimension.split('=');
            if (key === 'width') {
                width = value;
            } else if (key === 'height') {
                height = value;
            }
        });
    }

    return (
        <img
            src={src}
            alt={alt}
            style={{
                width: width,
                height: height,
                display: 'block',
                margin: '0 auto'
            }}
        />
    );
};

export default CustomImage;
