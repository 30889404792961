import React, {useEffect, useRef, useState} from "react";
import "./App.css";
import Home from "./Components/pages/Home";
import NewFooter from "./Components/organs/Footer.tsx";
import NavBar from "./Components/organs/NavBar";
import {ToastContainer} from "react-toastify";
import BlogComp from "./Components/pages/BlogComp";

let isLocalRun = false
const hostname = window.location.hostname;
if (hostname === "localhost" || hostname === "127.0.0.1") {
	isLocalRun = true
}

function App() {
	function handleClosePrivacyModal() {
		this.setState({privacyModalOpen: false});
		// localStorage.setItem('privacyAccepted', true);
		// console.log('privacyAccepted', localStorage.getItem('privacyAccepted'))
	}
	
	// if (localStorage.getItem('privacyAccepted') == 'false') {
	// 	window.location.href = 'https://applyjobs.ai/privacypolicy';
	// }
	// console.log('privacyAccepted', localStorage.getItem('privacyAccepted'));
	// console.log('privacyModalOpen', this.state.privacyModalOpen);
	return (
		<div className="app-container">
			{/*<Routes>*/}
			{/*	<Route*/}
			{/*		path="/paid-blog"*/}
			{/*		element={<ProtectedRoute element={<PaidBlog/>}/>}*/}
			{/*	/>*/}
			{/*/	<Route*/}
			{/*		path="/paid-member-chats"*/}
			{/*		element={<ProtectedRoute element={<PaidMemberChats/>}/>}*/}
			{/*	/>*/}
			{/*</Routes>*/}
			<NavBar isLocalRun={isLocalRun}/>
			<BlogComp useremail={'emailtoyd@gmail.com'}/>
			{/*<NewFooter/>*/}
			<ToastContainer/>
			{/*DO NO DELETE*/}
			{/*New Responsibitlies being transitioned here to this repo*/}
			{/*{this.state.privacyModalOpen && <PrivacyPopup onClose={this.handleClosePrivacyModal}/>}*/}
			{/*// var signedin = sessionStorage.getItem("signedin");*/}
			{/*// const privacyAccepted = localStorage.getItem('privacyAccepted');*/}
			{/*// console.log('privacyAccepted', localStorage.getItem('privacyAccepted'))*/}
			{/*// if (!privacyAccepted) {*/}
			{/*// 	this.setState({privacyModalOpen: false})*/}
			{/*// }*/}
		</div>
	
	);
}

export default App;
