import React from "react";
import PropTypes from "prop-types";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

const Author = ({ name }) => {
  const dummyContent = "Lorem ipsum dolor sit amet.";

  return (
    <div style={{"width":"160px","height":"80px"}} className="flex flex-col justify-around border border-gray-300  rounded-xl p-2 mr-2 bg-gray-800 text-white overflow-hidden">
      <div className="flex items-center ">
        <img
          src={`https://i.ibb.co/BZ7nc8n/image.png`}
          alt={name}
          className="w-8 h-8 rounded-full "
        />
        <div className="flex flex-col">
          <div className="text-sm font-bold ml-2">{name}</div>
          <div className="text-xs text-gray-100 ml-2">xyx123@gmail.com</div>
        </div>
      </div>

      <div className="flex space-x-2">
        <a href="#" className="">
          <GitHubIcon fontSize="smaller" />
        </a>
        <a href="#" className="text-pink-600 hover:text-pink-800">
          <InstagramIcon fontSize="smaller" />
        </a>
        <a href="#" className="text-blue-600 hover:text-blue-800">
          <FacebookIcon fontSize="smaller" />
        </a>
      </div>
    </div>
  );
};

Author.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Author;
