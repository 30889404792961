import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div style={{width:"20vw"}} className="bg-white p-5 py-10 rounded-lg shadow-lg relative">
        <HighlightOffIcon
          onClick={onClose}
          className="absolute top-0 right-0 m-2 pointer"
        ></HighlightOffIcon>

        {children}
      </div>
    </div>
  );
};

export default Modal;
